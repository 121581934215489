<template>
  <div class="font-weight-thin caption text-center" :style="colorStyle">
    Made with
    <v-icon x-small style="color:#ff3460;">fa-heart</v-icon>
    by <a style="font-weight: 500;" href="http://dataconsulting.mioti.es/">MIOTI Data & AI Services</a>
    &copy; {{ current_year }}
  </div>

</template>

<script>
export default {
  name: 'MadeWithLove',
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      current_year: new Date().getFullYear()
    }
  },
  computed: {
    colorStyle () {
      let textColor = this.$vuetify.theme.currentTheme.secondary
      if (this.isLogin) textColor = 'var(--v-primary-darken4)'
      return `color: ${textColor}`
    }
  }
}
</script>

<style scoped>

</style>
